 


<template>
  <v-main class="grey lighten-3">


        <v-row >
          <v-col cols="12" sm="3">
          </v-col>

          <!--메인:왼쪽 열  -->
          <v-col
            cols="12"
            lg="6" style="padding:25px">

     <v-card :loading="isLoadingCreateFirebase">

       <template slot="progress">
        <v-progress-linear
          color="deep-orange"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>

     <v-subheader>ADD QUEST</v-subheader>
     <v-card-text>
         <v-text-field 
            v-model="title" 
            label="Title(*)"
            required
            outlined/>

         <v-textarea 
            v-model="body" 
            label="Body(*)"
            required
            outlined/>

             <v-select
                v-model="selectedType"
                 outlined
                  :items="['Trust', 'Trustless', 'etc']"
                  label="Trust Type"
                  required
                ></v-select>
 
             
             <v-autocomplete
                 outlined
                  :items="['EOS', 'QUEST', 'EXP']"
                  label="Rewards"
                  multiple
                ></v-autocomplete>

                 <v-slider
                 class="mt-2"
                 v-model="targetMaxCount"
                 prepend-icon="mdi-account-plus"
                :thumb-size="24"
                thumb-label="always"
                min="1"
                max="100"

              ></v-slider>

               <!-- <v-date-picker
        v-model="dates"
        range
      ></v-date-picker> -->
         <!-- <v-text-field 
            v-model="link" b
            label="Link Url(*)"
            required
            outlined/>

        <v-text-field 
            v-model="imgLink" 
            label="Image Url"
            required
            outlined/>

              <v-text-field 
            v-model="youtubeID" 
            label="youtube ID"
            required
            outlined/> -->

     </v-card-text>

  

      <v-avatar class="ml-10" size="135" style="box-shadow: rgba(0, 0, 0, 0.68) 0px 3px 6px;">
        <v-img :src="profilePhotoURL" :style="isImageRatio > 1.6 ? bioImageStyle2:bioImageStyle1" />
      </v-avatar>

     <v-file-input
      class="ml-5 mr-5"
      :rules="rules"
      show-size
      accept="image/*"
      placeholder="Pick an Upload Image"
      prepend-icon="mdi-camera"
      label="Image"
      @change="previewImageFile1" 
  ></v-file-input>

   <v-file-input
     class="ml-5 mr-5"
    :rules="rules"
     show-size
    accept="image/png, image/jpeg, image/bmp"
    placeholder="Pick an Upload Image"
    prepend-icon="mdi-camera"
    label="Image"
     @change="previewImageFile2" 
  ></v-file-input>

   <v-file-input
     class="ml-5 mr-5"
    :rules="rules"
     show-size
    accept="image/png, image/jpeg, image/bmp"
    placeholder="Pick an Upload Image"
    prepend-icon="mdi-camera"
    label="Image"
     @change="previewImageFile3" 
  ></v-file-input>


  <v-subheader>Link Auth</v-subheader>

  <v-text-field class="ma-5"
            v-model="linkPublickKey" 
            label="LinkPublickKey(*)"
            required
            outlined/>

             <v-text-field class="ma-5"
            v-model="linkDisplayName" 
            label="linkDisplayName(*)"
            required
            outlined/>

     <v-card-actions>
         <!-- <v-btn @click="click1">choose photo</v-btn>
           <input type="file" ref="input1"
            style="display: none"
            @change="previewImage" accept="image/*" >             -->
         <v-btn @click="create">Create</v-btn>
         <v-btn @click="linkAuth">LinkAuth Powerup</v-btn>
         <v-btn @click="linkAuthCreateAccount">LinkAuth Create Account</v-btn>
     </v-card-actions>

    </v-card>

          </v-col>
        </v-row>
 

  </v-main>
</template>


<script>

import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc , serverTimestamp  } from "firebase/firestore"; 
import { db,storage } from '@/plugins/firebase';

// const storage = getStorage();

import Axios from 'axios';

// const pinataSDK = require('@pinata/sdk');
const pinataKey ='93b005cbebd919b8393f'
const pinataSK= '33e34784051f9c02dea6a4933fbed7291e7db5503d60719188b76f50d54209b1'
// const pinata = new pinataSDK(pinataKey, pinataSK)

export default {
  data () {
    return {
      isLoadingCreateFirebase:false,
      title:'',
      body:'',
      dates: ['2022-03-10', '2019-03-20'],
      rules: [
        value => !value || value.size < 200000000 || 'Avatar size should be less than 200 MB!',
      ],
      willUploadImages1:null,
      willUploadImages2:null,
      willUploadImages3:null,
      targetMaxCount:0,
      selectedType:'Trustless',
      profilePhotoURL:'',
      isImageRatio:1,
      bioImageStyle1:{
       width: "135px",
       height: "154px"
      },
      bioImageStyle2:{
       width: "135px",
       height: "205px"
      },
      linkPublickKey:'',
      linkDisplayName:'newaccount'
    
    }
  },
  methods: {

    linkAuth()
        {

            //linkPublickKey
            
             const action = [ 
             {
                  account: 'eosio',
                  name: 'updateauth',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: {
                    account:this.$store.state.mainAnchorSession.auth.actor,
                    auth: {
                      threshold: 1,
                      keys: [{
                          key: this.linkPublickKey,
                          weight: 1
                      }],
                      accounts: [],
                      waits: []
                    },
                  parent: 'active',
                  permission: this.linkDisplayName
                },
            },
            {
               account: 'eosio',
               name: 'linkauth',
               authorization: [this.$store.state.mainAnchorSession.auth],
               data:{
                   account: this.$store.state.mainAnchorSession.auth.actor,
                   code:'eosio',
                   requirement:this.linkDisplayName,
                   type:'powerup',
               },
            },
                
            ]; 

            this.$store.state.mainAnchorSession.transact({actions:action}).then((result) => 
            {
                console.log(result)
            })
            .catch((error) => {
                  console.log('errrro' + error)
            })
            .finally(()=> { 
                
            });

        },

         linkAuthCreateAccount()
        {

            //linkPublickKey
            
             const action = [ 
             {
                  account: 'eosio',
                  name: 'updateauth',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: {
                    account:this.$store.state.mainAnchorSession.auth.actor,
                    auth: {
                      threshold: 1,
                      keys: [{
                          key: this.linkPublickKey,
                          weight: 1
                      }],
                      accounts: [],
                      waits: []
                    },
                  parent: 'active',
                  permission: this.linkDisplayName
                },
            },
            {
               account: 'eosio',
               name: 'linkauth',
               authorization: [this.$store.state.mainAnchorSession.auth],
               data:{
                   account: this.$store.state.mainAnchorSession.auth.actor,
                   code:'eosio',
                   requirement:this.linkDisplayName,
                   type:'newaccount',
               },
            },
            {
               account: 'eosio',
               name: 'linkauth',
               authorization: [this.$store.state.mainAnchorSession.auth],
               data:{
                   account: this.$store.state.mainAnchorSession.auth.actor,
                   code:'eosio',
                   requirement:this.linkDisplayName,
                   type:'buyrambytes',
               },
            },
                
            ]; 

            this.$store.state.mainAnchorSession.transact({actions:action}).then((result) => 
            {
                console.log(result)
            })
            .catch((error) => {
                  console.log('errrro' + error)
            })
            .finally(()=> { 
                
            });

        },
        
        

    // create () {
      
    //   const post = {
    //     photo: this.img1,
    //     caption: this.caption        
    //   }
    // //   firebase.database().ref('PhotoGallery').push(post)
    // //   .then((response) => {
    // //     console.log(response)
    // //   })
    // //   .catch(err => {
    // //     console.log(err)
    // //   })
    // },
  click1() {
  this.$refs.input1.click()   
  },

   getMeta(url) {   
      var img = new Image();
      img.onload = function() {
          //console.log(this.width +" "+ this.height + " => " + this.height/this.width  );
          this.isImageRatio = this.height/this.width

          //console.log(this.isImageRatio > 1.6 ? 'width: 140px; height: 225px;':'width: 140px; height: 154px;')

      };
      img.src = url;
    },

async create(){

  this.isLoadingCreateFirebase = true;
  
  // this.isLoadingCreateFirebase = false;

  // return;
 

    if( this.title =='' ||  this.body =='') return;

    var tempImageCount= 0;
    if(this.willUploadImages1 != null) tempImageCount++;
    if(this.willUploadImages2 != null) tempImageCount++;
    if(this.willUploadImages3 != null) tempImageCount++;
 
    const docRef = await addDoc(collection(db, "quests"), 
    {
        title:  this.title,
        body: this.body.replaceAll('\n','<br>'),
        imgCount: tempImageCount,
        trustType:this.selectedType,
        tCount:this.targetMaxCount,
        regdate: serverTimestamp()
    });
  

    var refID =  docRef.id;
 
    if(this.willUploadImages1 != null) this.uploadFileToStorage(this.willUploadImages1,refID,0);
    if(this.willUploadImages2 != null) this.uploadFileToStorage(this.willUploadImages2,refID,1);
    if(this.willUploadImages3 != null) this.uploadFileToStorage(this.willUploadImages3,refID,2);

     this.isLoadingCreateFirebase = false;

  },
 
previewImageFile1(file) {
  this.willUploadImages1 = file;



  //https://docs.pinata.cloud/pinata-api/pinning/pin-file-or-directory
 
  //성공했음, 클라이언트에서 직접 pinata호출
  // const formData = new FormData();
  // formData.append('file', file);
 
  // const metadata = JSON.stringify({
  //     name: 'Filename02',
  //   });

  // formData.append('pinataMetadata', metadata);

  // Axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       'pinata_api_key': pinataKey,
  //       'pinata_secret_api_key': pinataSK
  //     }
  //   })
  //   .then(response => {
  //     console.log(response.data);
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });

  //두번째 node.js서버에 호출해서 결과값 받기

  // if(file == null)
  // {
  //    this.isImageRatio = 1
  //   this.profilePhotoURL = 'https://genesis.eden.eoscommunity.org/images/blank-profile-picture.svg'
  // }
  // else{

  //   this.profilePhotoURL = URL.createObjectURL(file);

  //   this.getMeta(this.profilePhotoURL)

  // }

  //  return;


  // if(file == null) return

  // const formData = new FormData();
  // formData.append('file', file);
 
  // //formData.append('pinataMetadata', metadata);
  // //var name = 'edenprofile_brandon'
  // var name = 'election_r1'

  // Axios.post(`http://localhost:3000/ipfspinata/${name}`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     }
  //   })
  //   .then(response => {
  //     console.log(response.data);
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });

    //  Axios.post(`http://localhost:3000/ipfspinata/${name}`, formData)
    // .then(response => {
    //   console.log(response.data);
    // })
    // .catch(error => {
    //   console.error(error);
    // });
    
  //다이렉트 video 테스트

  const formData = new FormData();
  formData.append('file', file);

    const metadata = JSON.stringify({
      name: 'election_r1',
    });

  formData.append('pinataMetadata', metadata);

    Axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'pinata_api_key': pinataKey,
        'pinata_secret_api_key': pinataSK
      }
    })
    .then(response => {
      console.log(response.data.IpfsHash);
    })
    .catch(error => {
      console.error(error);
    });


   
},
previewImageFile2(file) {
  this.willUploadImages2 = file;
},
previewImageFile3(file) {
  this.willUploadImages3 = file;
},
 uploadFileToStorage(file,refID,index)
 {
   
    const storageRef = ref(storage, 'images/'+refID+'/'+index+'-origin');
    const uploadTask =  uploadBytesResumable(storageRef, file);
     
    // // // Register three observers:
    // // // 1. 'state_changed' observer, called any time the state changes
    // // // 2. Error observer, called on failure
    // // // 3. Completion observer, called on successful completion
    // uploadTask.on('state_changed', (snapshot) => {
        
    //     // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     // this.progressValue = progress;
    //     // switch (snapshot.state) {
    //     // case 'paused':
    //     //     console.log('Upload is paused');
    //     //     break;
    //     // case 'running':
    //     //     console.log('Upload is running');
    //     //     break;
    //     // }
    // }, 
    // (error) => {
    //     // Handle unsuccessful uploads
    // }, 
    // () => {
    //     // Handle successful uploads on complete
    //     // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    //     getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    //      console.log(downloadURL);
          
    //     });
    // }
    // );

 }

  }
}
</script>